import { Trans, useTranslation } from 'react-i18next';

import { Logo } from '@/components/logo';
import {
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  TDS,
  useDisclosure,
  useToast,
} from '@/components/ui';
import { INQUIRY_EMAIL } from '@/constants/help';
import { useLoading } from '@/hooks/use-loading';
import { ErrorManager } from '@/libs/auth/managers/error';
import { copyTextToClipboard } from '@/utils/clipboard';
import { cn } from '@/utils/cn';
import { isLocalMode } from '@/utils/env';

import { EmailSignInButton } from './components/email-sign-in-button';
import { EmailSignInView } from './components/email-sign-in-view';
import { FacebookSignInButton } from './components/facebook-sign-in-button';
import { GoogleSignInButton } from './components/google-sign-in-button';

const DEV_TYPECAST_REGISTOR_URL = import.meta.env
  .VITE_DEV_TYPECAST_REGISTOR_URL;
const TYPECAST_REGISTOR_URL = import.meta.env.VITE_TYPECAST_REGISTOR_URL;
/**
 * TODO: google sign in button 랜더링시 크기 설정 관련 문서화 필요
 */
export default function SigninPage() {
  const loading = useLoading();
  const [isEmailSignInOpen, setIsEmailSignInOpen] = useState<boolean>(false);
  const accountErrorHandler = useDisclosure();
  const toast = useToast();
  const { t, i18n } = useTranslation(['signInPage']);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const handleSignInStart = () => {
    loading.start();
  };

  const handleSignInEnd = () => {
    loading.end();
  };

  const handleSignInError = (err: Error) => {
    if (!ErrorManager.isTypecastAuthError(err)) {
      setErrorMessage(t(`error.auth/common`));
      return;
    }
    if (i18n.exists(`error.${err.code}`, { ns: 'signInPage' })) {
      setErrorMessage(t(`error.${err.code}`));
      return;
    }
    setErrorMessage(t(`error.auth/common`));
  };

  const showEmailSignIn = () => {
    setIsEmailSignInOpen(true);
  };
  const hideEmailSignIn = () => {
    setIsEmailSignInOpen(false);
  };

  const handleCopyEmail = () => {
    copyTextToClipboard(INQUIRY_EMAIL, () => {
      toast({
        position: 'top',
        render: () => (
          <div className="flex cursor-pointer justify-center gap-3 rounded-xl bg-grey-900 px-4 py-3 ">
            <i className="i-t-alert text-6 text-white" />
            <TDS.Typo color="white" size="md">
              이메일 주소가 클립보드에 복사되었습니다.
            </TDS.Typo>
          </div>
        ),
      });
    });
  };

  return (
    <>
      <Logo />
      <div
        style={{ maxWidth: '460px', width: '100%', padding: '3rem' }}
        className="relative h-628px bg-white flex flex-col items-stretch justify-center rounded-32px overflow-hidden gap-10"
      >
        {loading.isLoading && (
          <div className="absolute bottom-0 left-0 right-0 top-0 z-10 flex items-center justify-center rounded-2xl bg-white/60">
            <TDS.Spinner color="blackAlpha" size="lg" />
          </div>
        )}
        <TDS.Typo as="h2" size="2xl" weight="bold" className="text-center">
          {t('sign-in')}
        </TDS.Typo>
        <div className="flex flex-col place-items-center gap-4">
          <GoogleSignInButton
            onBeforeSignIn={handleSignInStart}
            onSignInError={handleSignInError}
            onAfterSignIn={handleSignInEnd}
          />
          <FacebookSignInButton
            onBeforeSignIn={handleSignInStart}
            onSignInError={handleSignInError}
            onAfterSignIn={handleSignInEnd}
          />
          <EmailSignInButton onClick={showEmailSignIn} />
        </div>
        {errorMessage && (
          <TDS.Typo
            as="p"
            color="error.500"
            textAlign={'center'}
            size="md"
            weight="medium"
            aria-errormessage={errorMessage}
          >
            {errorMessage}
          </TDS.Typo>
        )}
        <Divider />
        <div>
          <TDS.Button
            as="a"
            href={
              isLocalMode() ? DEV_TYPECAST_REGISTOR_URL : TYPECAST_REGISTOR_URL
            }
            rel="noreferrer"
            variant="outline"
            colorScheme="grey"
            size="md"
            rounded="full"
            w="full"
            mb={2}
          >
            {t('create_account')}
          </TDS.Button>
          <TDS.Typo
            size="xs"
            variant="normal"
            className="block color-grey-600 text-center w-full"
          >
            <Trans
              t={t}
              components={{
                b: <b className="color-grey-900" />,
              }}
            >
              helper-text
            </Trans>
          </TDS.Typo>
        </div>
        {isEmailSignInOpen && (
          <EmailSignInView
            className={cn(
              'absolute w-full h-full left-0 top-0 bg-white px-12',
              {},
            )}
            onClose={hideEmailSignIn}
            onError={handleSignInError}
            onBeforeSignIn={handleSignInStart}
            onAfterSignIn={handleSignInEnd}
            errorMessage={errorMessage}
          />
        )}
      </div>

      <Modal
        isOpen={accountErrorHandler.isOpen}
        onClose={accountErrorHandler.onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent rounded={16}>
          <ModalHeader>{t('sign_in_failed.title')}</ModalHeader>
          <ModalBody>
            <TDS.Typo as="p" color="grey.700">
              <Trans t={t} components={{ br: <br /> }}>
                sign_in_failed.content
              </Trans>
            </TDS.Typo>
          </ModalBody>
          <ModalFooter>
            <TDS.Button
              colorScheme="grey"
              size="md"
              onClick={handleCopyEmail}
              mr={3}
            >
              {t('sign_in_failed.copy-email')}
            </TDS.Button>
            <TDS.Button
              colorScheme="primary"
              size="md"
              onClick={accountErrorHandler.onClose}
            >
              {t('sign_in_failed.close')}
            </TDS.Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
